import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

import "../css/main.css";
// import "@/theme/variables.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHouse,
  faBolt,
  faCircleXmark,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faUpLong,
  faStairs,
  faGlobe,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faHouse,
  faBolt,
  faCircleXmark,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faUpLong,
  faStairs,
  faGlobe,
  faTableCellsLarge
);

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

router.isReady().then(() => {
  app.mount("#app");
});
